<template>
  <div>
    <h1>Ajouter une Faculté</h1>
    <form-faculte ref="formFac" />
    <el-divider></el-divider>
    <div style="margin-top: 8px">
      <el-button type="primary" :disabled="isSaving" @click="onSubmit"
        >Enregistrer</el-button
      >
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </div>
</template>
<script>
import FormFaculte from "@/components/rh/ui/form/FormFaculte";
import { strNormalize } from "@/fonctions";

export default {
  components: { "form-faculte": FormFaculte },

  data() {
    return {
      isSaving: false,
    };
  },
  props: {
    routes: Object,
  },
  created() {
    // reset le form
    this.$store.commit("fac/resetFaculte", "");
  },
  computed: {
    faculteCreateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.fac.faculteCreateStatus;
    },
  },
  methods: {
    onSubmit() {
      this.$refs.formFac.$refs.formFaculte.validate((valid) => {
        if (valid) {
          // récupère les données de la boîte de dialogue
          const bd = this.$store.state.fac.faculte;
          // enlever les espaces avant et après dans le nom de la nouvelle fac
          bd.fac = bd.fac.trim();

          // vérifie si la faculté est  déjà dans la bd
          const FacsInBD = this.$store.getters["fac/facultesInBD"];
          // nom de la fac en minuscule et sans espace et sans accents pour la comparaison
          const facNormalize = strNormalize(bd.fac);

          if (FacsInBD.filter((m) => m[2] == facNormalize).length > 0) {
            this.$alert(
              "Cette faculté est déjà enregistrée dans la base de données !",
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            //crée un nouveau enregistrement
            this.$store.dispatch("fac/createFaculte", {
              id_pays: bd.id_pays,
              fac: bd.fac,
            });
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      // retourne à la liste des facultés
      this.$router.push(this.routes.tolist);
    },
  },
  watch: {
    faculteCreateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.faculteCreateStatus == 2) {
        // message succès
        this.$message({
          message: "La faculté a été ajoutée à la base de donnée",
          type: "success",
        });

        // va au formulaire d'édition du la thèse
        this.$router.push(this.routes.toedit);
      } else if (this.faculteCreateStatus == 3) {
        this.isSaving = false;
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      } else if (this.faculteCreateStatus == 1) {
        this.isSaving = true;
      } else if (this.faculteCreateStatus == 0) {
        this.isSaving = false;
      }
    },
  },
};
</script>
