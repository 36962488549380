<template>
  <el-main class="rh-form">
    <form-facultecreate :routes="facRoutes" />
  </el-main>
</template>
<script>
import FormFacultecreate from "@/components/rh/formulaires/FormFacultecreate";

export default {
  components: { "form-facultecreate": FormFacultecreate },
  computed: {
    facRoutes() {
      return {
        toedit: {
          name: "faculteedit",
          params: { idfac: this.$store.state.fac.faculteLastCreatedId },
        },
        tolist: { name: "facultes" },
      };
    },
  },
};
</script>
